/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
/* body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; } */
body {
  margin: 0;
  background-color: #ecf1f1;
}
* {
  font-family: "Poppins", sans-serif;
  /* font-family: Arial, Helvetica, sans-serif; */
}

.breakword {
  word-wrap: break-word;
}
.textfontcolor {
  color: white;
}

.streached .mat-tab-label-active {
  /*TO  change the aligment of details and pref uncommment below two line*/
  /* min-width: 25px !important;
    padding: 5px; */
  /* background-color:#0F059E; */

  background-color: #10069f;
  /* background-image: linear-gradient(to right, #3F9588 0%, #296b61 75%); */
  color: white;
  font-weight: 700;
  transition: ease-in 0.5s;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  /* background-color: #3f9588; */
}

.useritems {
  display: flex;
}

.streached .mat-tab-label {
  /* min-width: 60px !important; */
  padding: 5px;
  opacity: 1;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 10px;
  border-radius: 5px;

  background-color: #b8c6db;
}

.cm-text-color {
  /* color: #3f9588; */
}

.flex {
  display: flex;
}

.marginforicon {
  margin-left: 3px;
}

.maintab .mat-tab-label {
  padding: 5px;
  opacity: 1;
  /* border: 1px solid #3F9588; */
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 10px;
  border-radius: 5px;
  color: white;
  background-color: #7e8185;
}

.maintab .mat-tab-label-active {
  /*TO  change the aligment of details and pref uncommment below two line*/
  /* min-width: 25px !important;
      padding: 5px; */
  /* background-color:#0F059E; */

  background-color: #10069f;
  /* background-image: linear-gradient(to right, #3F9588 0%, #296b61 75%); */
  color: white;
  font-weight: 700;
  transition: ease-in 0.5s;
}

.swal2-html-container{

  font-size: 15px !important;

}